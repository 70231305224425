/* Dependencies
 ---------------------------------------------------------- */
import { Application } from 'wrapper6'

/* Services
  ---------------------------------------------------------- */

/* Register services
  ---------------------------------------------------------- */

/* Modules
  ---------------------------------------------------------- */
import ObjectFit from './modules/_object-fit'
import Print from './modules/_print'
import Navigation from './modules/_navigation'
import NavigationSearch from './modules/_navigation-search'

/* Vendor
  ---------------------------------------------------------- */

/* Setup
  ---------------------------------------------------------- */
const app = window.app = new Application(window.options || {})

/* Register modules
  ---------------------------------------------------------- */
app.use(ObjectFit)
app.use(Print)
app.use(Navigation)
app.use(NavigationSearch)

require('./app')
