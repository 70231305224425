require('objectFitPolyfill')

export default class ObjectFitClass {
  constructor () {
    /**
         * Object-fit poly-fill for IE & Edge
         */

    if ('objectFit' in document.documentElement.style === false) {
      const container = document.getElementsByClassName('object-fit')

      for (let i = 0; i < container.length; i++) {
        let imageSource = container[i].querySelector('img').src
        const imageSourceFull = container[i].querySelector('img').getAttribute('full')
        imageSource = (imageSourceFull !== '' && imageSourceFull !== null) ? imageSourceFull : imageSource
        container[i].querySelector('img').style.display = 'none'
        container[i].style.backgroundSize = 'cover'
        container[i].style.backgroundImage = 'url(' + imageSource + ')'
        container[i].style.backgroundPosition = 'center center'
        container[i].style.backgroundRepeat = 'no-repeat'
      }
    }

    // for video tag
    objectFitPolyfill()
  }
}
