import { Module } from 'wrapper6'

const $ = jQuery

export default class NavigationSearch extends Module {
  ready (app) {
    this.searchBtn = document.querySelector('.mtt-header .mtt-header__search-wrapper')
    this.addSearchClick()
  }

  addSearchClick () {
    if (this.searchBtn) {
      this.searchBtn.addEventListener('click', (e) => {
        this.form = this.searchBtn.querySelector('.mtt-header__main-search-box')

        if (this.searchBtn === e.target) {
          this.toggleForm()
        }
      })

      this.clickOutsideOfForm()
    }
  }

  clickOutsideOfForm () {
    window.addEventListener('click', (e) => {
      if (document.querySelector('.form-open')) {
        if (!document.querySelector('.mtt-header .mtt-header__search-wrapper').contains(e.target)) {
          this.removeForm()
        }
      }
    })
  }

  toggleForm () {
    if (!this.form.classList.contains('form-open')) {
      this.form.classList.add('form-open')
      this.form.querySelector('input[type="text"]').focus()
    } else {
      this.form.classList.remove('form-open')
    }
  }

  removeForm () {
    if (this.form.classList.contains('form-open')) {
      this.form.classList.remove('form-open')
    }
  }
}
