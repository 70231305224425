import { Module } from 'wrapper6'
import ObjectFitClass from '../utils/_object-fit'

const $ = jQuery

export default class ObjectFit extends Module {
  ready (app) {
    new ObjectFitClass()
  }
}
