import { Module } from 'wrapper6'
import { debounce } from '../utils/_debounce'
import anime from 'animejs'
import NavigationScrollHandler from '../utils/_navigation-scroll'

export default class Navigation extends Module {
  ready (app) {
    const MAIN_HEADER = document.getElementById('main_header')

    if (MAIN_HEADER) {
      const RESPONSIVE_NAVIGATION = new ResponsiveNavigation('main_nav', 'navigation-toggle')
      const RESPONSIVE_NAVIGATION_SCROLL_HANDLER = new NavigationScrollHandler({
        scrollDistance: 80,
        headerElement: MAIN_HEADER,
        onEnter: () => {
          !document.body.classList.contains('header-scrolled') && document.body.classList.add('header-scrolled')
          !MAIN_HEADER.classList.contains('mtt-header--scrolled') && MAIN_HEADER.classList.add('mtt-header--scrolled')
        },
        onExit: () => {
          document.body.classList.contains('header-scrolled') && document.body.classList.remove('header-scrolled')
          MAIN_HEADER.classList.contains('mtt-header--scrolled') && MAIN_HEADER.classList.remove('mtt-header--scrolled')
        }
      })
    }
  }
}

class ResponsiveNavigation {
  constructor (ID, TOGGLE_ID) {
    this.ID = ID
    this.TOGGLE_ID = TOGGLE_ID
    this.BREAKPOINT = 1160
    this.canToggle = true

    // Elements
    this.NAV = document.getElementById(ID)
    this.MAIN_HEADER = document.getElementById('main_header')
    this.NAV_OVERLAY = document.getElementById('overlay')
    this.NAV_TOGGLE = document.getElementById(TOGGLE_ID)

    this.appendDropdownArrows()
    this.bindEventHandlers()
  }

  bindEventHandlers () {
    // close nav
    this.NAV_OVERLAY.addEventListener('click', () => this.closeNavigation())
    window.addEventListener('resize', debounce(() => this.closeNavigation(), 300))
    document.addEventListener('keydown', (e) => e.keyCode === 27 && this.closeNavigation())
    this.submenuOnClick()

    // toggle nav
    this.NAV_TOGGLE.addEventListener('click', () => this.toggleNavigation())
  }

  toggleNavigation () {
    // Check if toggleable
    this.toggleable()

    // Toggle elements
    this.NAV_TOGGLE.classList.toggle('open')
    this.NAV.classList.toggle('open-menu')
    this.NAV_OVERLAY.classList.toggle('show')
  }

  // Check if menu is toggleable
  toggleable () {
    if (!this.canToggle) return
    this.canToggle = false
    setTimeout(() => { this.canToggle = true }, 200)
  }

  // Close the navigation
  closeNavigation () {
    this.NAV_TOGGLE.classList.remove('open')
    this.NAV.classList.remove('open-menu')
    this.NAV_OVERLAY.classList.remove('show')
  }

  // Append dropdown arrows for items with children
  appendDropdownArrows () {
    const MENUS_WITH_CHILDREN = document.querySelectorAll('.mtt-header .menu-item-has-children')

    for (let i = 0; i < MENUS_WITH_CHILDREN.length; i++) {
      const MENU_ITEM = MENUS_WITH_CHILDREN[i]
      const DROPDOWN_ELEMENT = document.createElement('div')
      DROPDOWN_ELEMENT.className = 'mtt-header__dropdown-arrow'
      DROPDOWN_ELEMENT.addEventListener('click', this.toggleSubMenu.bind(this))
      MENU_ITEM.appendChild(DROPDOWN_ELEMENT)
    }
  }

  // Close menu on submenu item click
  submenuOnClick () {
    const ALL_MENU_ITEMS = document.querySelectorAll('#menu-main-menu .current_page_item li')
    for (let i = 0; i < ALL_MENU_ITEMS.length; i++) {
      ALL_MENU_ITEMS[i].addEventListener('click', () => this.closeNavigation())
    }
  }

  // Toggle submenu
  toggleSubMenu (e) {
    if (this.getScreenWidth() <= 1160) {
      const THIS = e.target
      const MENU_WRAP = THIS.parentNode
      const SUBMENU_WRAP = MENU_WRAP.querySelector('.sub-menu')
      const SUBMENU_ITEMS = SUBMENU_WRAP.querySelectorAll('.menu-item')

      MENU_WRAP.classList.toggle('menu-open')

      if (MENU_WRAP.classList.contains('menu-open')) {
        this.subMenuAnimation(SUBMENU_ITEMS)
      } else {
        this.hideSubMenuItems(SUBMENU_ITEMS)
      }
    }
  }

  subMenuAnimation (targetElements) {
    anime({
      targets: targetElements,
      translateX: ['-50px', 0],
      opacity: [0, 1],
      elasticity: 100,
      duration: 700,
      delay: function (el, i) {
        return i * 75
      }
    })
  }

  hideSubMenuItems (targetElements) {
    for (let i = 0; i < targetElements.length; i++) {
      const SUBMENU_ITEM = targetElements[i]
      SUBMENU_ITEM.style.transform = 'translateX(-100%)'
    }
  }

  /************************************************
  * Helpers
  ***********************************************/

  getScreenWidth () {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }

  getScrollY () {
    return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
  }

  getHeaderHeight () {
    return this.MAIN_HEADER.getBoundingClientRect().height
  }
}
