import { Module } from 'wrapper6'

const $ = jQuery

export default class Print extends Module {
  ready (app) {
    $(document).on('click', '.print', function (e) {
      window.print()

      e.preventDefault()
    })
  }
}
